<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <title>performance-money-increase</title>
    <path
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      d="M21.75.75,14.4,8.1a1.5,1.5,0,0,1-1.933.16L9.173,5.909a1.5,1.5,0,0,0-1.809.049L.75,11.25"
    />
    <polyline
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      points="15.75 0.75 21.75 0.75 21.75 6.75"
    />
    <rect
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      x="14.25"
      y="20.25"
      width="9"
      height="3"
      rx="0.75"
      ry="0.75"
    />
    <rect
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      x="2.25"
      y="20.25"
      width="9"
      height="3"
      rx="0.75"
      ry="0.75"
    />
    <rect
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      x="0.75"
      y="17.25"
      width="9"
      height="3"
      rx="0.75"
      ry="0.75"
    />
    <rect
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      x="2.25"
      y="14.25"
      width="9"
      height="3"
      rx="0.75"
      ry="0.75"
    />
    <rect
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      x="12.75"
      y="17.25"
      width="9"
      height="3"
      rx="0.75"
      ry="0.75"
    />
    <rect
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      x="14.25"
      y="14.25"
      width="9"
      height="3"
      rx="0.75"
      ry="0.75"
    />
    <rect
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      x="14.25"
      y="11.25"
      width="9"
      height="3"
      rx="0.75"
      ry="0.75"
    />
  </svg>
</template>
